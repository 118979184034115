<template>
  <v-container>
    <v-row class="d-flex justify-center">
      <v-col cols="12" sm="11" md="11" lg="8">
        <page-header>{{ $t('about.about_us') }}</page-header>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12" sm="11" md="11" lg="8">
        <page-header-subtitle>{{ $t('about.story') }}</page-header-subtitle>
      </v-col>
    </v-row>

    <v-row id="vision" class="d-flex mx-10 justify-center primary lighten-3 my-16 elevation-4 rounded-lg">
      <v-col class="d-flex rounded align-center" cols="12" sm="4">
        <v-img max-height="250px" contain src="./assets/undraw_new_ideas_jdea.svg"></v-img>
      </v-col>

      <v-col cols="12" sm="6" class="d-flex flex-column justify-center font-weight-light pa-6 pa-md-10">
        <span class="text-h4 text-md-h3">{{ $t('about.our_vision') }}</span>
        <span class="text-subtitle-1 text-md-h6 font-weight-light">{{ $t('about.vision_text') }}</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="text-center pt-16 mb-10">
        <span class="text-h3 text-md-h3">{{ $t('about.the_ppl') }}</span>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center pb-10">
      <v-col cols="12" sm="10" md="8" lg="7" xl="5">
        <v-row>
          <v-col cols="6" sm="6" md="6" lg="4" xl="4" v-for="(person, index) in people" :key="index" class="d-flex flex-column align-center text-center">
            <v-sheet class="rounded-circle elevation-2">
              <v-img class="rounded-circle" :height="imageSize" :width="imageSize" :src="person.src"></v-img>
            </v-sheet>

            <span class="text-subtitle-1 font-weight-light pt-2">{{ person.name }}</span>
            <span class="text-subtitle-2">{{ $t('about.' + person.position) }}</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import PageHeader from '@/components/PageHeader';
import PageHeaderSubtitle from '@/components/PageHeaderSubtitle';

export default {
  name: 'About',
  components: {
    PageHeader,
    PageHeaderSubtitle,
  },
  data: function () {
    return {
      imageSize: 100,
      people: [
        {
          name: 'Stian Aasen',
          position: 'pos_ceo',
          src: require('./assets/people/Stian_Aasen.jpg'),
        },
        {
          name: 'Kent Aasen',
          position: 'pos_bus_dev',
          src: require('./assets/people/Kent_Aasen.jpg'),
        },
        {
          name: 'Martin Landsem',
          position: 'pos_cto',
          src: require('./assets/people/Martin_Landsem.jpg'),
        },
        {
          name: 'Jim Flaten',
          position: 'pos_op_man_dev',
          src: require('./assets/people/Jim_Flaten.jpg'),
        },
        {
          name: 'Magne Petersen',
          position: 'pos_cust_succ_man',
          src: require('./assets/no_face.png'),
        },
        {
          name: 'Joar Jacobsen',
          position: 'pos_dev_frontend',
          src: require('./assets/people/Joar_Jacobsen.jpg'),
        },
        {
          name: 'Maiken Reinfjell',
          position: 'pos_dev',
          src: require('./assets/people/Maiken_Reinjfell.jpg'),
        },
        {
          name: 'Per-Olov Gunnar',
          position: 'pos_hr_salary',
          src: require('./assets/no_face.png'),
        },
        {
          name: 'Andreas Hoberg',
          position: 'pos_integrations',
          src: require('./assets/no_face.png'),
        },
        {
          name: 'Harry Aasen',
          position: 'pos_economy',
          src: require('./assets/no_face.png'),
        },
        {
          name: 'Torbjørn Kallstad',
          position: 'pos_cust_succ_man',
          src: require('./assets/people/Torbjorn_Kallstad.jpg'),
        },
        {
          name: 'Jens Christian Saltvedt Haarstad',
          position: 'pos_dev',
          src: require('./assets/people/Jens_Christian_Saltvedt_Haarstad.jpg'),
        },
        {
          name: 'Arthur Senn',
          position: 'pos_dev',
          src: require('./assets/people/Arthur_Senn.jpg'),
        },
        {
          name: 'Benjamin Pralong',
          position: 'pos_cust_succ_man',
          src: require('./assets/people/Benjamin_Pralong.png'),
        },
        {
          name: 'Michał Gajewski',
          position: 'pos_team_lead',
          src: require('./assets/people/Michal_Gajewski.png'),
        },
        {
          name: 'Ewa Kozicka',
          position: 'pos_dev_backend',
          src: require('./assets/people/Ewa_Kozicka.png'),
        },
        {
          name: 'Joanna Celuch',
          position: 'pos_project_coordinator',
          src: require('./assets/people/Joanna_Celuch.jpg'),
        },
        {
          name: 'Kamil Jakubowski',
          position: 'pos_dev_backend',
          src: require('./assets/no_face.png'),
        },
        {
          name: 'Witold Pokrzywnicki',
          position: 'pos_dev_frontend',
          src: require('./assets/people/Witold_Pokrzywnicki.png'),
        },
        {
          name: 'Marie Echeverria',
          position: 'pos_cust_deploy_assistant',
          src: require('./assets/no_face.png'),
        },
        {
          name: 'Marius Stenstvedt',
          position: 'pos_dev_junior',
          src: require('./assets/people/Marius_Stenstvedt.jpg'),
        },
      ],
    }
  },
}
</script>
<style scoped>

</style>
